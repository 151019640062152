import { Modal } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../../../app/store'
import { AttCommon, Contact } from '../../../../../models'
import {
  getCurrentFiltersText,
  renderNomCode,
  renderNull,
} from '../../../../../utils'
import './CommonModal.scss'

const FournisseurViewOnly = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pageIndex = Number(searchParams.get('page-index')) || 1
  const pageSize = Number(searchParams.get('page-size')) || 25

  const { dataAttendu } = useSelector((state: RootState) => state.modal)

  const columnsAdresses: ColumnsType<AttCommon> = [
    {
      title: <div className="form-label">Complément</div>,
      dataIndex: 'complement',
      key: 'complement',
      width: '12%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Adresse</div>,
      dataIndex: 'adresse',
      key: `adresse`,
      width: '28%',
      className: 'modal-column',
      render: (_, row) => renderNull(row.addresse as string),
    },
    {
      title: <div className="form-label">Code postal</div>,
      dataIndex: 'code_postal',
      key: 'code_postal',
      width: '18%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Ville</div>,
      dataIndex: 'ville',
      key: 'ville',
      width: '12%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Pays</div>,
      dataIndex: 'pays',
      key: 'pays',
      width: '12%',
      className: 'modal-column',
      render: (_, row) =>
        `${renderNull(row.pays)} (${renderNull(row.iso as string)})`,
    },
    {
      title: <div className="form-label">GLN</div>,
      dataIndex: 'gln',
      key: 'gln',
      width: '18%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
  ]

  const columnsContact: ColumnsType<Contact> = [
    {
      title: <div className="form-label">Nom</div>,
      dataIndex: 'contact',
      key: 'nom',
      width: '28%',
      className: 'modal-column',
      render: (el: string) => renderNull(el),
    },
    {
      title: <div className="form-label">Tel</div>,
      dataIndex: 'tel',
      key: 'tel',
      width: '21%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Mobile</div>,
      dataIndex: 'mobile',
      key: 'mobile',
      width: '21%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Email</div>,
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      className: 'modal-column',
      render: (el) => renderNull(el),
    },
  ]

  const handleClose = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate(
        `/gestion-de-stock/attendu?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
      )
    }
  }

  return (
    <Modal
      destroyOnClose
      title={
        <div className="flex text-primary text-xl font-bold items-center border-0 border-b border-b-gray3 border-solid -mt-5">
          <p className="mr-4" style={{ fontSize: '28px' }}>
            Fournisseur
          </p>
          <p className="font-normal text-2xl my-4">
            (n° {renderNomCode(dataAttendu.code_nom as string)[0]})
          </p>
        </div>
      }
      open={true}
      footer={null}
      onCancel={handleClose}
      className="modal border-solid border-secondary rounded-xl"
      bodyStyle={{ overflowY: 'auto', height: 'calc(100vh - 330px)' }}
    >
      <div className="w-full">
        <>
          <>
            <div className="bg-gray2 flex items-center px-4 py-3 mb-4 rounded-lg">
              <>
                <p className="text-gray font-semibold mr-3 text-base">Nom</p>
                <div className="common-modal-nom">
                  {renderNomCode(dataAttendu.code_nom as string)[1]}
                </div>
              </>
            </div>
            <div className="bg-gray2 py-1 px-4 rounded-lg mb-4 ">
              <p className="text-primary font-bold text-lg border-0 border-b border-b-gray3 border-solid pb-4">
                Adresses
              </p>
              <Table
                rowKey="id"
                columns={columnsAdresses}
                dataSource={[dataAttendu]}
                pagination={false}
                className="custom-common-modal mb-4 mt-7"
              />
            </div>
            <div className="bg-gray2 pt-1 pb-4 px-4 rounded-lg mb-4">
              <p className="text-primary font-bold text-lg border-0 border-b border-b-gray3 border-solid pb-4">
                Contact
              </p>
              <Table
                rowKey="id"
                columns={columnsContact}
                dataSource={[dataAttendu]}
                pagination={false}
                className="custom-common-modal mb-4 mt-7"
              />
            </div>
            <hr className="border-0 border-b border-b-gray3 border-solid pt-4" />
          </>
        </>
      </div>
    </Modal>
  )
}

export default FournisseurViewOnly
